import React from "react";
import './pricing.css';
import 'react-toastify/dist/ReactToastify.css';


export default function Pricing(){

  return(
    <div className="main">
      Pricing Page
    </div>
  );
}
