import "../userList/userList.css"

function UserList(){
    
    return(
        <div className="userList">
            <div className="users">
                <h3>All User List</h3>

            </div>
        </div>
    )
}

export default UserList;