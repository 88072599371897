import React from "react";
import './about.css';
import 'react-toastify/dist/ReactToastify.css';


export default function About(){

  return(
    <div className="main">
      About Page
    </div>
  );
}
